import _ from "lodash";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { colors } from "@mui/material";
import typography from "./typography";

const baseOptions = {
  typography,
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#3f51b5", // Blue color for table head
          color: "black", // White text color
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: "#000", // Text color for the day
          "&:hover": {
            backgroundColor: "#1F9874", // Background color on hover
            color: "#ffffff", // Text color on hover
          },
          "&.Mui-selected": {
            backgroundColor: "#1F9874", // Background color for selected date
            color: "#ffffff", // Text color for selected date
          },
          "&.Mui-disabled": {
            color: "#BDBDBD", // Color for disabled days
            backgroundColor: "transparent", // Ensure background is transparent for disabled days
          },
        },
      },
    },
    MuiDayCalendarWeek: {
      styleOverrides: {
        root: {
          // Styles for the week container
          backgroundColor: "#f2f2f2", // Background color for week container
          borderRadius: "8px", // Border radius for week container
        },
      },
    },
    MuiDayCalendarMonth: {
      styleOverrides: {
        root: {
          // Styles for the month container
          backgroundColor: "#ffffff", // Background color for month container
          borderRadius: "8px", // Border radius for month container
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        labelContainer: {
          color: "#000", // Change the color of the calendar header labels
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          color: "#000", // Text color for year buttons
          "&:hover": {
            backgroundColor: "#1F9874", // Background color on hover
            color: "#ffffff", // Text color on hover
          },
          "&.Mui-selected": {
            backgroundColor: "#1F9874", // Background color for selected year
            color: "#ffffff", // Text color for selected year
          },
        },
      },
    },
    MuiYearCalendar: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff", // Background color for the year calendar
          borderRadius: "8px", // Border radius for the year calendar
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-of-type(even)": {
            backgroundColor: "#f5f5f5", // Light gray for even rows
          },
          "&:nth-of-type(odd)": {
            backgroundColor: "#fff", // White for odd rows
          },
          "&:hover": {
            backgroundColor: "#e0e0e0", // Light gray on row hover
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          // Add your custom styles here
          backgroundColor: "#f0f0f0", // Example background color
          color: "#333", // Example text color
          fontSize: "0.875rem", // Example font size
          // textAlign:"center",
          // Add other styles as needed
        },
        head: {
          // Styles for table header cells
          backgroundColor: "#1F9874", // Example header background color
          color: "#fff", // Example header text color
          fontWeight: "bold",
          padding: "8px",
          // textAlign:"center"
        },
        body: {
          // Styles for table body cells
          padding: "13px", // Example padding
          borderBottom: "1px solid gray !important",
        },
      },
    },
    MuiTableRow: {
      root: {
        border: "1px solid gray",
        "&:hover": {
          backgroundColor: "#1F9874",
        },
        "&:last-child": {
          borderBottom: "none",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: { color: "#222" },
        colorSecondary: {
          "&.Mui-focused": {
            color: "#222",
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: "#000000",
          fontSize: "22px !important",
          fontWeight: "600 !important",
          lineHeight: "33px !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "rgba(0, 0, 0, 0.05)",
          borderRadius: "7px",
          height: "48px",
        },
        notchedOutline: {
          borderColor: "rgba(0, 0, 0, 0.08)",
        },
        colorSecondary: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            color: "#222",
            borderColor: "#222",
          },
          "&.Mui-focused": {
            color: "#222",
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: "black",
        },
        outlined: {
          color: "black",
        },
        page: {
          "&.Mui-selected": {
            background: "#1F9874",
            color: "white",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          padding: "20px",
          width: "100%",
        },
        elevation1: {
          background: "#fff",
          borderRadius: "10px",
          padding: "26px 20px",
          boxShadow: "none",
        },
        elevation2: {
          // background:
          //   "linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0) 68.15%)",
          border: "none",
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "none",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 99999,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: { paddingLeft: "20px" },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          alignItems: "self-start",
        },
        gutters: {
          paddingLeft: 0,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "4px",
          fontSize: "12px",
        },
        colorPrimary: {
          "&.Mui-checked": { color: "#1F9874" },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          paddingBottom: "0",
          color: "black",
        },
      },
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: {
          right: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperScrollPaper: {
          Width: 450,
          maxWidth: "100%",
        },
        paper: {
          overflowY: "unset",
          borderRadius: "20px !important",
          padding: "20px",
        },
        paperWidthSm: {
          // maxWidth: "900px !important",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 14,
          color: "#222",
          height: "0.1876em",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          fontFamily: "Outfit !important",
          fontSize: "12px !important",
          fontWeight: "400 !important",
          lineHeight: "18px !important",
          letterSpacing: "0px !important",
          textAlign: "left !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          color: "#1F9874",
          height: "50px",
          padding: "10px 40px",
          fontSize: "14px",
          border: "2px solid #1F9874",
          background: "#fff",
          fontWeight: "500",
          lineHeight: "21px",
          fontFamily: "'Outfit'",
          borderRadius: "8px",
          textTransform: "none",
          backgroundColor: "#F2F2F2",
          // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
          "&:hover": {
            background: "white",
            // border: "2px solid #fff",
            boxShadow: "none !important",
            color: "#fff",
            backgroundColor: "linear-gradient(135deg, #1F9874, #3ACAB6)",
          },
        },
        containedPrimary: {
          color: "#fff",
          height: "50px",
          padding: "10px 40px",
          fontSize: "14px",
          background: "linear-gradient(135deg, #1F9874, #3ACAB6)",
          textTransform: "none",

          fontWeight: "500",
          lineHeight: "21px",
          fontFamily: "Outfit",
          borderRadius: "8px",
          // border: "2px solid #1F9874",
          // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
          // backgroundColor:
          //   "linear-gradient(275deg, #4D164F 4.07%, #1F9874 98.21%)",
          "&:hover": {
            color: "#1F9874",
            border: "2px solid #1F9874",
            background: "#fff !important",
          },
        },
        contained: {
          color: "#fff",
          height: "40px",
          padding: "10px 40px",
          fontSize: "14px",
          background: "#fff",
          // border: "1px solid #4D164F",
          fontWeight: "500",
          lineHeight: "21px",
          fontFamily: "Outfit",
          borderRadius: "50px",
          textTransform: "none",
          // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
          backgroundColor:
            "linear-gradient(275deg, #4D164F 4.07%, #1F9874 98.21%)",
          "&:hover": {
            color: "#fff",
            // border: "1px solid #E1E1E1",
            backgroundColor: "#F2F2F2",
          },
        },
        outlinedPrimary: {
          color: "#080515",
          border: "1px solid #4D164F",
          padding: "5px 25px",
          fontWeight: "500",
          borderRadius: "50px",
          fontSize: "13px",
          "&:hover": {
            backgroundColor: "#4D164F",
            border: "1px solid #4D164F",
            color: "#fff",
          },
        },
        outlinedSizeSmall: {
          padding: "6px 23px",
          fontSize: "16px",
          lineHeight: "24px",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorDockedLeft: {
          borderRight: "0",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: "0",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          padding: "0px !important",
        },
        root: {
          borderBottom: "none",
          padding: "0px !important",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: "600",
          padding: "0px 0px 13px 0px",
          borderBottom: "2px solid #000",
          marginBottom: "19px",
          fontSize: "18px",
          lineHeight: "27px",
          color: "#000",
          fontFamily: "Outfit",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "0",
          overflowY: "unset",
          "&:first-of-type": {
            paddingTop: "0",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: "3px",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        subtitle2: {
          lineHeight: "22px",
        },
        body2: {
          lineHeight: "16px",
          fontFamily: "Outfit",
          fontWeight: "400",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: "6px",
          color: "black",
          "&:hover": {
            backgroundColor: "rgba(40, 33, 33, 0.04)",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: "1px solid",
          "&.Mui-selected": {
            color: "#ffffff",
            backgroundColor: "linear-gradient(135deg, #1F9874, #3ACAB6)",
          },
        },
      },
    },
  },
};

const themesOptions = [
  {
    palette: {
      mode: "light",
      action: {
        // active: colors.blueGrey[600],
        primary: "#20509e",
      },
      background: {
        default: "#fff",
        dark: "#f3f7f9",
        paper: colors.common.white,
      },
      primary: {
        main: "#1F9874",
        dark: "#080515",
        light: "rgba(0, 0, 0, 0.60)",
      },
      secondary: {
        main: "#000000",
      },
      warning: {
        main: "#ffae33",
        dark: "#ffae33",
        light: "#fff1dc",
      },
      success: {
        main: "#54e18c",
        dark: "#54e18c",
        light: "#e2faec",
      },
      error: {
        main: "#DC0404",
        dark: "#DC0404",
        light: "#DC0404",
      },
      text: {
        primary: "#fff",
        secondary: "#848484",
      },
      common: {
        black: "#222222",
      },
    },
    shadows: Array(25).fill("none"),
  },
  {
    palette: {
      mode: "dark",
      background: {
        default: "#282C34",
        paper: "#282C34",
      },
      primary: {
        main: "#8A85FF",
      },
      secondary: {
        main: "#6E759F",
      },
      text: {
        primary: "#F5F5F5",
        secondary: "#B9B9B9",
      },
    },
    shadows: Array(25).fill("none"),
  },
];

export const createMuiTheme = (config = {}) => {
  let themeOptions = themesOptions.find(
    (theme) => theme.palette.mode === config.theme
  );

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );
  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
